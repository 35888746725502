import { Box } from '@mui/material'
import React from 'react';
import './sass/Map.css'

const Map = () => {
  return (
    <>
      <Box className="greenBorder">
        <Box className="embed-responsive embed-responsive-1by1 mapContainer">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.6440743449502!2d145.18476151532707!3d-38.10194777970119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60daa0ae475ab%3A0x883cf75f64c0eecb!2sCarrum%20Downs%20Dental%20Group!5e0!3m2!1sen!2sin!4v1665147177379!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </Box>
      </Box>
    </>
  )
}

export default Map