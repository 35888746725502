import React from 'react'
import { Helmet } from 'react-helmet'
import { SimpleHero, Sidebar } from '../../Components/Components'
import { Box, Container, Grid, List, Typography } from '@mui/material'

const SmileDesign = () => {
    const breadcrumb = [{ id: "breadcrumb-2.1", link: "/service/", text: "SERVICES" }, { id: "breadcrumb-2.2", link: null, text: "SMILE DESIGN" }]
    return (
        <>
            <Helmet>
                <meta name="description" content="" />
                <title>Smile Design</title>
                <meta name="robots" content="index" />
                <link rel="canonical" href='/smile-design/' />
            </Helmet>
            <SimpleHero pageTitle="Child Benefit" breadCrumb={breadcrumb} />
            <main>
                <Container maxWidth="xxl">
                    <Grid container>
                        <Grid item xs={11} md={10} className="mx-auto">
                            <Box py={5}>
                                <Grid container spacing={10}>
                                    <Grid item xs={12} lg={8}>
                                        
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                    <Sidebar slug={`/service/smile-design/`} isFormAvailable={false} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </>
    )
}

export default SmileDesign